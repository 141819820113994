import React from "react";
import { Typography } from "@ryerson/frontend.typography";
import { Flex, FlexItem } from "@ryerson/frontend.layout";
import Layout from "@components/Layout";
import ButtonLink from "@components/ButtonLink";
import Container from "@components/Container";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { MainContent } from "@graphQL/mainContent";
import { useTheme } from "@ryerson/frontend.theme";
import Dots from "@components/MoreDots";
import { renderRichText } from "@components/Contentful";
import { Video } from "@components/Video";
import Meta, { MetaContent } from "@components/MetaHelmet";

const SEO: MetaContent = {
	title: "Expertise - The Next Shift",
	description:
		"Ryerson Advanced Processing has the capabilities and market expertise to help manufacturers build a flexible and resilient supply chain.",
	url: "/expertise",
};

const Divider = styled.div`
	display: block;
	width: 100%;
	height: 1px;
	box-sizing: border-box;
	opacity: 0.2;
`;

const HeaderBanner = styled.div`
	display: block;
	width: 100%;
	height: auto;
	background-position: center center;
	background-size: cover;
`;

const HeaderOverlay = styled.div`
	width: 100%;
	height: auto;
	padding: 110px 0px 128px 0px;
`;

const DotsContainer = styled.div`
	display: block;
	width: 100%;
	height: 216px;
	position: relative;
`;

const Spacer = styled.div`
	display: block;
	width: 100%;
	height: 140px;
`;

export const hexToRgb = (hex: string) => {
	let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
	return result
		? String(parseInt(result[1], 16)) +
				", " +
				String(parseInt(result[2], 16)) +
				", " +
				String(parseInt(result[3], 16))
		: null;
};

export default () => {
	const mainContent = MainContent();
	const { theme } = useTheme();

	return (
		<Layout>
			<Meta content={SEO} />
			<HeaderBanner
				css={css`
					background-image: url(${mainContent.expertiseHeroImage.file.url});
				`}
			>
				<HeaderOverlay
					css={css`
						background-color: rgba(
							${hexToRgb(theme.colors.primary.secondaryBrand)},
							0.95
						);
						@media (max-width: ${theme.breakpoints.lg}) {
							padding: 60px 0px 70px 0px;
						}
					`}
				>
					<Container
						css={css`
							width: 950px;
						`}
					>
						<Typography
							variant="h1"
							type="tertiary"
							css={css`
								text-align: center;
								font-size: 36px;
								line-height: 42px;
								text-transform: uppercase;
								margin-bottom: 30px;
								@media (max-width: ${theme.breakpoints.lg}) {
									font-size: 24px;
									line-height: 30px;
									margin-bottom: 20px;
								}
							`}
						>
							{mainContent.expertiseHeroTitle}
						</Typography>
						<Typography
							variant="div"
							size="md"
							color={theme.colors.primary.white}
							css={css`
								margin-bottom: 60px;
								text-align: center;
								@media (max-width: ${theme.breakpoints.lg}) {
									margin-bottom: 40px;
								}
							`}
						>
							{mainContent.expertiseHeroSubtitle}
						</Typography>
						<div
							css={css`
								text-align: center;
								display: block;
								width: 100%;
							`}
						>
							<ButtonLink
								to="https://mktg.ryerson.com/l/513681/2023-08-15/gfrm22"
								external={true}
								css={css`
									margin-left: auto;
									margin-right: auto;
								`}
							>
								Contact an Expert
							</ButtonLink>
						</div>
					</Container>
				</HeaderOverlay>
			</HeaderBanner>
			<DotsContainer>
				<Dots
					type="light"
					css={css`
						height: 216px;
					`}
				/>
				<Typography
					variant="div"
					weight="bold"
					color={theme.colors.primary.secondaryBrand}
					css={css`
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						text-align: center;
						width: calc(100% - 40px);
						max-width: 1040px;
						padding: 20px;
						box-sizing: border-box;
						background-color: ${theme.colors.primary.white};
						div {
							font-size: 24px !important;
							line-height: 37px !important;
						}
						@media (max-width: ${theme.breakpoints.lg}) {
							padding: 10px;
							div {
								font-size: 18px !important;
								line-height: 24px !important;
							}
						}
					`}
				>
					{renderRichText(mainContent.expertiseDottedText.raw)}
				</Typography>
			</DotsContainer>
			<Container>
				<Flex
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					css={css`
						gap: 30px;
						margin-top: 100px;
						@media (max-width: ${theme.breakpoints.lg}) {
							flex-direction: column-reverse;
							justify-content: flex-start;
							margin-top: 30px;
							gap: 20px;
						}
					`}
				>
					<FlexItem
						css={css`
							width: calc(50% - 15px);
							@media (max-width: ${theme.breakpoints.lg}) {
								width: 100%;
							}
						`}
					>
						<Typography
							variant="h2"
							css={css`
								margin-bottom: 30px;
								max-width: 490px;
								@media (max-width: ${theme.breakpoints.lg}) {
									margin-left: auto;
									margin-right: auto;
								}
							`}
						>
							{mainContent.expertiseLeftTextTitle}
						</Typography>
						<Typography
							variant="div"
							css={css`
								margin-bottom: 30px;
								max-width: 490px;
								@media (max-width: ${theme.breakpoints.lg}) {
									margin-left: auto;
									margin-right: auto;
								}
							`}
							size="md"
						>
							{mainContent.expertiseLeftTextParagraph.expertiseLeftTextParagraph}
						</Typography>
						<a href={mainContent.expertiseLeftTextLink} target="_blank">
							<Typography
								variant="div"
								color={theme.colors.primary.primaryBrand}
								css={css`
									display: inline-block;
									padding: 13px 26px;
									border-radius: 45px;
									border: 1px solid ${theme.colors.primary.primaryBrand};
									@media (max-width: ${theme.breakpoints.lg}) {
										padding:10px; 20px;
									}
								`}
							>
								Learn More
							</Typography>
						</a>
					</FlexItem>
					<FlexItem
						css={css`
							width: calc(50% - 15px);
							@media (max-width: ${theme.breakpoints.lg}) {
								width: 100%;
							}
						`}
					>
						<Video
							videoId={mainContent.expertiseRightVideoLink}
							imageUrl={mainContent.expertiseRightVideoThumbnail.file.url}
						/>
					</FlexItem>
				</Flex>
				<Spacer
					css={css`
						@media (max-width: ${theme.breakpoints.lg}) {
							height: 20px;
						}
					`}
				/>
				<Flex
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					css={css`
						gap: 30px;
						@media (max-width: ${theme.breakpoints.lg}) {
							flex-direction: column;
							justify-content: flex-start;
							gap: 20px;
						}
					`}
				>
					<FlexItem
						css={css`
							width: calc(50% - 15px);
							@media (max-width: ${theme.breakpoints.lg}) {
								width: 100%;
							}
						`}
					>
						<iframe
							css={css`
								width: 100%;
								border: none;
								height: 350px;
								@media (max-width: ${theme.breakpoints.lg}) {
									height: 300px;
								}
							`}
							src={"https://www.thinglink.com/card/" + mainContent.expertiseThinglink}
						></iframe>
					</FlexItem>
					<FlexItem
						css={css`
							width: calc(50% - 15px);
							@media (max-width: ${theme.breakpoints.lg}) {
								width: 100%;
							}
						`}
					>
						<Typography
							variant="h2"
							css={css`
								margin-bottom: 30px;
								max-width: 490px;
								margin-left: auto;
								@media (max-width: ${theme.breakpoints.lg}) {
									margin-right: auto;
								}
							`}
						>
							{mainContent.expertiseRightTextTitle}
						</Typography>
						<Typography
							variant="div"
							css={css`
								margin-bottom: 30px;
								max-width: 490px;
								margin-left: auto;
								@media (max-width: ${theme.breakpoints.lg}) {
									margin-right: auto;
								}
							`}
							size="md"
						>
							{mainContent.expertiseRightTextParagraph.expertiseRightTextParagraph}
							<Spacer
								css={css`
									height: 30px;
									@media (max-width: ${theme.breakpoints.lg}) {
										height: 20px;
									}
								`}
							/>
							<a href={mainContent.expertiseLeftTextLink} target="_blank">
								<Typography
									variant="div"
									color={theme.colors.primary.primaryBrand}
									css={css`
										display: inline-block;
										padding: 13px 26px;
										border-radius: 45px;
										border: 1px solid ${theme.colors.primary.primaryBrand};
										@media (max-width: ${theme.breakpoints.lg}) {
											padding: 10px 20px;
										}
									`}
								>
									Learn More
								</Typography>
							</a>
						</Typography>
					</FlexItem>
				</Flex>
			</Container>
			<DotsContainer
				css={css`
					margin-top: 140px;
					height: 264px;
					@media (max-width: ${theme.breakpoints.lg}) {
						margin-top: 40px;
					}
				`}
			>
				<Dots
					type="light"
					css={css`
						height: 264px;
					`}
				/>
				<Typography
					variant="div"
					css={css`
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						text-align: center;
						width: calc(100% - 40px);
						max-width: 580px;
						padding: 24px 50px;
						box-sizing: border-box;
						background-color: ${theme.colors.primary.white};

						@media (max-width: ${theme.breakpoints.lg}) {
							padding: 10px 20px;
						}
					`}
				>
					<Typography
						variant="h2"
						css={css`
							font-size: 40px;
							line-height: 48px;
							margin-bottom: 20px;
						`}
						weight="bolder"
					>
						LET'S WORK TOGETHER
					</Typography>
					<ButtonLink
						external={true}
						to={"https://mktg.ryerson.com/l/513681/2023-08-15/gfrm22"}
					>
						Contact an Expert
					</ButtonLink>
				</Typography>
			</DotsContainer>
			<Container
				css={css`
					padding-top: 60px;
				`}
			>
				<Divider
					css={css`
						border-top: 1px solid ${theme.colors.tertiary.background};
					`}
				/>
			</Container>
		</Layout>
	);
};
